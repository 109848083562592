@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply box-content inline-flex cursor-pointer justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium;
    @apply shadow shadow-gray-300;
    @apply hover:shadow-gray-400;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
    @apply active:outline-none active:ring-2 active:ring-offset-2;
    @apply disabled:pointer-events-none;
  }

  .btn-icon {
    @apply p-1 text-cyan-800 focus:ring-cyan-500 active:ring-cyan-500;
    @apply disabled:text-gray-400;
  }

  .btn-primary {
    @apply bg-cyan-600 text-white hover:bg-cyan-700 focus:ring-cyan-500;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  padding: 1rem 0;
}
